<template>
  <div class="card mx-auto max-w-6xl">
    <h1 class="card-header text-center">
      Seekit Integrations Documentation
    </h1>
    <div class="card-body text-lg leading-6">
      <h2 class="text-3xl my-4">
        Seekit App for Zoom
      </h2>
      <h3 class="text-2xl my-3">
        How to Connect Seekit app for Zoom
      </h3>
      <p class="mb-3">
        Linking your Seekit account to the Zoom app is straightforward. Please follow these simple steps:
      </p>
      <ol class="steps-list list-decimal pl-5 mb-6">
        <li>
          Ensure your Zoom account uses the same email as your Seekit account. This is crucial for the linkage between your accounts. If they don't match, consider creating a new Seekit account with an email address matching the one used on your Zoom account.
        </li>
        <li>
          <a
            target="_blank"
            :href="url"
            class="cta-link"
          >
            Navigate to the Seekit app for Zoom URL
          </a> and hit the "connect" button on the Zoom app store to integrate the Seekit app.
        </li>
        <li>
          Verify your connection. If the email addresses match, your Seekit account will automatically connect to the app. The integrations page will display a "Connected" status, confirming the successful integration.
        </li>
      </ol>
      <h3 class="text-2xl my-3">
        Optimize Your Seekit app for Zoom Usage
      </h3>
      <p class="mb-6">
        Using Seekit app for Zoom is straightforward. Record your desired Zoom meetings and Seekit will analyze them. The transcripts will appear on the Seekit Conversations page, where your selected analyzers will process the data.
      </p>

      <h3 class="text-2xl my-3">
        Disconnecting the Seekit app for Zoom
      </h3>
      <p class="mb-6">
        If you wish to remove the Seekit app for Zoom, follow these steps:
        <ul class="pl-5 list-disc mb-6">
          <li>First, from the Seekit interface, navigate to the integrations page, find the Zoom integration card, and click the "Disconnect" button. After confirmation, the status will change to "Disconnected".</li>
          <li>Next, to fully disconnect, navigate to the Zoom App Marketplace and remove the Seekit app for Zoom from there as well:
            <br> 1. Login to your Zoom Account and navigate to the Zoom App Marketplace.
            <br> 2. Click Manage >> Added Apps or search for the "Seekit" app.
            <br> 3. Click the "Seekit" app
            <br> 4. Click Remove
            <br> Once completed, the Seekit Integration page should show a "Disconnected" status for Zoom.</li>
        </ul>
      </p>
      <h3 class="text-2xl my-3">
        Seekit app for Zoom Troubleshooting
      </h3>
      <dl class="mb-6">
        If you encounter issues with the Seekit app for Zoom, review our solutions to common problems:
        <dt><i>Problem: Seekit app for Zoom isn't connecting in Seekit.</i></dt>
        <dd>Solution: Ensure your Zoom and Seekit account emails match exactly. If not, create a new Seekit account using the same email address you used for Zoom.</dd>
        <br>
        <dt><i>Problem: Conversations aren't appearing in Seekit.</i></dt>
        <dd>Solution: Use a premium Zoom account to record meetings to the cloud. Wait a few minutes for Seekit to retrieve the conversations, then you should see them appear on your Seekit homepage.</dd>

        <br>
        <dt><i>Problem: Conversations are not being analyzed.</i></dt>
        <dd>Solution: Confirm your analyzers are turned on in Seekit and allow a few minutes for processing. If no analyzers are enabled, Seekit cannot process your conversation.</dd>

        <br>
        <dt><i>Problem: Zoom meetings aren't showing up in Seekit.</i></dt>
        <dd>Solution: Ensure you're recording meetings to the Zoom cloud. Local recordings must be manually transcribed using a third party service and then uploaded to Seekit for analysis.</dd>
      </dl>

      <h3 class="text-2xl my-3">
        Still Need Help?
      </h3>
      <p class="mb-3">
        If you're experiencing issues not covered here, don't hesitate to email our support team at
        <a
          href="mailto:support@seekit.io"
          class="text-blue-500 underline"
        >
          support@seekit.io
        </a>.
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const url = ref('https://zoom.us/oauth/authorize?response_type=code&client_id=GjZUUPEyRymdwmNQNQrSmw&redirect_uri=https://dev.seekit.io/api/public/zoom-integrations/oauth-access-token');

</script>
