<template>
  <div class="card">
    <h2 class="card-header">
      Integrations
    </h2>
    <div class="card-body">
      <DataTable
        :fields="integrationFields"
        :rows="currentPage"
        :total-pages="totalPages"
        :search-enabled="true"
        :load-list="getList"
        :is-loading="$isLoading('getListIntegration')"
        :actions="integrationActions"
        :empty-message="'No integrations'"
        class="w-full"
      />
      <div class="flex flex-row-reverse mt-4">
        <button
          :disabled="$isLoading('getListIntegration')"
          @click="$refs.newIntegrationDialog.openDialog()"
        >
          New Integration
        </button>
      </div>
    </div>

    <NewIntegrationDialog ref="newIntegrationDialog" />
    <EditIntegrationDialog ref="editIntegrationDialog" />
    <DeleteIntegrationDialog ref="deleteIntegrationDialog" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import DataTable from '#ui/components/DataTable';
import { INTEGRATION_MODEL_FIELDS } from '#features/integrations/lib/models/IntegrationModel';
import NewIntegrationDialog from '#features/integrations/ui/dialogs/NewIntegrationDialog';
import EditIntegrationDialog from '#features/integrations/ui/dialogs/EditIntegrationDialog';
import DeleteIntegrationDialog from '#features/integrations/ui/dialogs/DeleteIntegrationDialog';

// Setup

const store = useStore();
const currentPage = computed(() => store.state.Integrations.currentPage);
const totalPages = computed(() => store.state.Integrations.totalPages);
const integrationFields = computed(() => omit(INTEGRATION_MODEL_FIELDS, 'id'));

// List navigation

const getList = loadable(
  (values) => store.dispatch('Integrations/getList', values),
  'getListIntegration',
  getCurrentInstance(),
);

// Row actions

const editIntegrationDialog = ref(null);
const openEditDialog = (integration) => {
  editIntegrationDialog.value.openDialog(integration);
};

const deleteIntegrationDialog = ref(null);
const openDeleteDialog = (integration) => {
  deleteIntegrationDialog.value.openDialog(integration);
};

const integrationActions = [
  { name: 'Edit Integration', fn: openEditDialog },
  { name: 'Delete Integration', fn: openDeleteDialog },
];
</script>
