<template>
  <div class="card">
    <h2 class="card-header">
      Integrations
    </h2>
    <div class="flex card-body">
      <div class="grid-container">
        <div
          v-for="integration in currentPage"
          :key="integration.id"
          class="grid-card"
        >
          <div class="grid-card-header-integration">
            <img
              class="grid-card-image"
              :src="getIntegrationImage(integration.name)"
              :alt="integration.name"
            >
            <h3 class="grid-card-title grid-card-centered">
              {{ integration.name }}
            </h3>
          </div>
          <p class="grid-card-description grid-card-centered">
            {{ integration.description }}
          </p>
          <div class="grid-card-button-wrapper">
            <button
              v-if="!integration.connected"
              class="btn-primary"
              @click="connect(integration.name)"
            >
              Connect
            </button>
            <button
              v-if="integration.connected"
              v-element-hover="integration.onHover"
              :class="integration.hover ? '' : 'btn-secondary'"
              @click="disconnect(integration.name)"
            >
              {{ integration.hover ? 'Disconnect' : 'Connected' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <NewZoomIntegrationDialog ref="newZoomIntegrationDialog" />
    <DeleteZoomIntegrationDialog ref="deleteZoomIntegrationDialog" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, onMounted } from 'vue';
import { vElementHover } from '@vueuse/components';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import { INTEGRATION_MODEL_FIELDS } from '#features/integrations/lib/models/IntegrationModel';
import NewZoomIntegrationDialog from '#features/zoom-integration/ui/dialogs/NewZoomIntegrationDialog';
import DeleteZoomIntegrationDialog from '#features/zoom-integration/ui/dialogs/DeleteZoomIntegrationDialog';

// Setup
/* eslint-disable */
const store = useStore();
const currentPage = computed(() => {
  let integrations = store.state.Integrations.currentPage;
  integrations.forEach(integration => {
    integration.onHover = ( state ) => {
      integration.hover = state;
    }
    integration.hover = false;
  });
  return integrations;
});

const totalPages = computed(() => 1);
const integrationFields = computed(() => omit(INTEGRATION_MODEL_FIELDS, 'id'));

// List navigation

const getList = loadable(
  (values) => store.dispatch('Integrations/getList', values),
  'getListIntegration',
  getCurrentInstance(),
);

const nameToImageMap = {
  'Zoom': require('#ui/assets/integration_logos/zoom.svg'),
};

const getIntegrationImage = (name) => {
  if (name in nameToImageMap){
    return nameToImageMap[name];
  }
  return require('#ui/assets/integration_logos/default.svg');
}

const connected = ref(false);

const newZoomIntegrationDialog = ref(null);
const openZoomDialog = () => {
  newZoomIntegrationDialog.value.openDialog();
};

const connectDialogToNameMap = {
  'Zoom': openZoomDialog,
}

const deleteZoomIntegrationDialog = ref(null);
const openZoomDeleteDialog = () => {
  deleteZoomIntegrationDialog.value.openDialog();
};

const disconnectDialogToNameMap = {
  'Zoom': openZoomDeleteDialog,
}

const connect = (name) => {
  if (name in connectDialogToNameMap) {
    connectDialogToNameMap[name]();
  }
  return;
}

const disconnect = (name) => {
  if (name in disconnectDialogToNameMap) {
    disconnectDialogToNameMap[name]();
  }
  return;
}

const getPage = async () => {
  try {
    await getList({
      page: 1,
      per: 20,
      search: '',
    });
  }
  catch (error) {
    console.log(error);
    console.log('error');
  }
};

onMounted(async () => {
  await getPage();
});

</script>
