<template>
  <Dialog
    :visible="visible"
    :style="{ width: '50vw', 'max-width': '500px' }"
    :breakpoints="{'640px': '100vw'}"
    :closable="false"
    header="New Integration"
  >
    <form
      class="dialog-body"
      @submit.prevent="submit"
    >
      <text-input
        v-model="form.name.value"
        class="mb-4"
        input-id="name"
        label="Name"
        :error-text="nameErrorText"
        :disabled="$isLoading('createIntegration')"
      />
      <text-input
        v-model="form.icon.value"
        class="mb-4"
        input-id="icon"
        label="Icon"
        :error-text="iconErrorText"
        :disabled="$isLoading('createIntegration')"
      />
      <text-input
        v-model="form.description.value"
        class="mb-4"
        input-id="description"
        label="Description"
        :error-text="descriptionErrorText"
        :disabled="$isLoading('createIntegration')"
      />
    </form>

    <div class="dialog-buttons">
      <loading-spinner
        v-if="$isLoading('createIntegration')"
        :size-class="'fa-2x'"
      />

      <button
        :disabled="$isLoading('createIntegration')"
        @click="closeDialog"
      >
        Cancel
      </button>

      <button
        class="btn-primary"
        :disabled="! formValid || $isLoading('createIntegration')"
        @click="submit"
      >
        Create
      </button>
    </div>
  </Dialog>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { deepUnref } from 'vue-deepunref';
import { loadable } from 'vue-is-loading';
import {
  IntegrationValidator,
  INTEGRATION_FORM_FIELDS,
} from '#features/integrations/lib/validators/IntegrationValidator';
import { getErrorMessage } from '#lib/Errors';
import { fieldErrorText, applyErrors } from '#ui/lib/forms';
import { successToast, errorToast } from '#ui/lib/toast';
import TextInput from '#ui/components/TextInput';
import LoadingSpinner from '#ui/components/LoadingSpinner';

// Setup

const store = useStore();
const form = {
  name: ref(''),
  icon: ref(''),
  description: ref(''),
};

// Validation

const apiErrors = {
  name: ref([]),
  icon: ref([]),
  description: ref([]),
};
const validator = new IntegrationValidator(INTEGRATION_FORM_FIELDS);
const nameErrorText = computed(fieldErrorText('name', validator, form, apiErrors));
const iconErrorText = computed(fieldErrorText('icon', validator, form, apiErrors));
const descriptionErrorText = computed(fieldErrorText('description', validator, form, apiErrors));

const formValid = computed(() => validator.valid(form));
const resetApiErrors = () => {
  apiErrors.name.value = [];
  apiErrors.icon.value = [];
  apiErrors.description.value = [];
};

// Open/close

const visible = ref(false);
const openDialog = () => {
  form.name.value = '';
  form.icon.value = '';
  form.description.value = '';
  apiErrors.name.value = [];
  apiErrors.icon.value = [];
  apiErrors.description.value = [];

  visible.value = true;
};
const closeDialog = () => {
  visible.value = false;
};
defineExpose({ openDialog, closeDialog });

// New Integration

const createIntegration = loadable(
  (values) => store.dispatch('Integrations/createIntegration', values),
  'createIntegration',
  getCurrentInstance(),
);
const submit = async () => {
  resetApiErrors();

  if (! validator.valid(form)) {
    applyErrors(apiErrors, validator.errors(form, apiErrors));
    return;
  }

  try {
    await createIntegration(deepUnref(form));

    successToast('Integration saved.');
    closeDialog();
  }
  catch (error) {
    errorToast(getErrorMessage(error, 'Could not update Integration.'));
  }
};
</script>
