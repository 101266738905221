<template>
  <div class="card">
    <h2 class="card-header">
      communicators
    </h2>
    <div class="card-body">
      <DataTable
        :fields="communicatorFields"
        :rows="currentPage"
        :total-pages="totalPages"
        :search-enabled="true"
        :load-list="getList"
        :is-loading="$isLoading('getListCommunicator')"
        :actions="communicatorActions"
        :empty-message="'No communicators'"
        class="w-full"
      />

      <div class="flex flex-row-reverse mt-4">
        <button
          :disabled="$isLoading('getListCommunicator')"
          @click="$refs.newCommunicatorDialog.openDialog()"
        >
          New Communicator
        </button>
      </div>
    </div>

    <NewCommunicatorDialog ref="newCommunicatorDialog" />
    <EditCommunicatorDialog ref="editCommunicatorDialog" />
    <DeleteCommunicatorDialog ref="deleteCommunicatorDialog" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import DataTable from '#ui/components/DataTable';
import { COMMUNICATOR_MODEL_FIELDS } from '#features/communicators/lib/models/CommunicatorModel';
import NewCommunicatorDialog from '#features/communicators/ui/dialogs/NewCommunicatorDialog';
import EditCommunicatorDialog from '#features/communicators/ui/dialogs/EditCommunicatorDialog';
import DeleteCommunicatorDialog from '#features/communicators/ui/dialogs/DeleteCommunicatorDialog';

// Setup

const store = useStore();
const currentPage = computed(() => store.state.Communicators.currentPage);
const totalPages = computed(() => store.state.Communicators.totalPages);
const communicatorFields = computed(() => omit(COMMUNICATOR_MODEL_FIELDS, 'id'));

// List navigation

const getList = loadable(
  (values) => store.dispatch('Communicators/getList', values),
  'getListCommunicator',
  getCurrentInstance(),
);

// Row actions

const editCommunicatorDialog = ref(null);
const openEditDialog = (communicator) => {
  editCommunicatorDialog.value.openDialog(communicator);
};

const deleteCommunicatorDialog = ref(null);
const openDeleteDialog = (communicator) => {
  deleteCommunicatorDialog.value.openDialog(communicator);
};

const communicatorActions = [
  { name: 'Edit Communicator', fn: openEditDialog },
  { name: 'Delete Communicator', fn: openDeleteDialog },
];
</script>
